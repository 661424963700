
import OSS from 'ali-oss';

export const ossConfig = {
	// return {
		region: 'oss-cn-hongkong',
		// accessKeyId: 'LTAI5t9pUsVQBnwyGgeGGuc4',
		// accessKeySecret: 'XljA667qnWvCmtwchv0mfyQPevtUsw',
		//2023.05.19
		accessKeyId: 'LTAI5tMPbxqCUDpaxdiEWbqc',
		accessKeySecret: 'BYmS5ZcgCQYmM0p38SBtsvA7Exfg5c',
		// bucket: bucket,
		endpoint: 'oss-cn-hongkong.aliyuncs.com',
		// endpoint: 'hytx-oss-wh.oss-accelerate.aliyuncs.com',//加速
		secure: true //https
	// }
}

export const ossClient = (bucket) => {
	let config = ossConfig;
	config.bucket = bucket;
	return new OSS(config);
}

/**
 * 
 * oss 上传
 * 
 * option:{
		folder:"storage/label/",
	 	fileKey:"lable_trucking",
	 	fileId: userId,
	 	file: base64toFile(base64,fileName,'application/pdf') 
 }
*/
export const ossUpload = async (option) => {
	
	let client = ossClient('hytx-oss-wh');
	// let config = ossConfig;
	// config.bucket = bucket;
	// return new OSS(config);
	let file = option.file;
	console.log('option.file', option.file);
	//
	// let bucket = this.bucket;
	let relativePath = '';
	// let relativePathArr = [this.folder, this.fileKey, this.fileId, new Date().getTime(), file.name.substr(file.name.lastIndexOf('.')).toLowerCase()];
	//替换影响链接的字符
	let fileName = file.name ? file.name.replace(/(\+|\%|\#|\&|\\|\/|\=|\?|\:|\s)/g, "_") : '';
	let relativePathArr = [option.folder, option.fileKey, option.fileId, new Date().getTime(), fileName];
	relativePath = relativePathArr.join('_');
	try {
	// 分片上传文件
		let ret = await client.multipartUpload(relativePath, file, {
			progress: async p => {
				console.log(p);
				// let e = {};
				// e.percent = p * 100;
				// option.onProgress(e);
				// this.showProgress = true;
				// this.progress = Math.floor(p * 100);
				// if (100 == this.progress) {
				// 	// vm.loading = false;
				// }
			}
		});
		console.log(ret);
		return ret;
		// if (ret.res.statusCode === 200) {
		// 	console.log('onSuccess');
		// 	//调用此处，执行两次
		// 	// option.onSuccess(ret)
		// 	return ret;
		// } else {
		// 	// vm.loading = false;
		// 	// option.onError(this.$t('module.upload.fail_upload'));
		// }
	} catch (error) {
		console.error(error);
		// this.loading = false;
		// option.onError(this.$t('module.upload.fail_upload') + 'code:1');
		// this.$error(error.message);
	}
	
}